import React, { FC, useMemo, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button, Spin, Tooltip } from 'antd';
import { useStore } from '../StoreProvider';
import { getNameArray, makeCoverage, getOrganizationId } from '../../utils/helper';
import _ from 'lodash';
import { api, createPatient, createCoverage } from '../../api';
import { ObjectId } from 'bson';
import { ENROLLED, REFER_PATIENT, DISCHARGED, INIT } from '../../utils/constants';
import moment from 'moment';
import Icon, { CheckCircleFilled } from '@ant-design/icons';
import DupPatientListModal from '../Modal/DupPatientListModal';
import ReferPatientSuccesModal from '../Modal/ReferPatientSuccessModal';
import classes from './PatientSnapshot.module.scss';
import TwoButtonMessageModal from '../Modal/TwoButtonMessageModal';

const getPatientAddress = (address = []) => {
  const entry = address[0];
  return entry ? [entry.city, entry.state].filter(item => !!item).join(', ') : 'No Address';
};

const getInsurance = (records) => {
  if (!records) {
    return "";
  }
  const coverage = _.find(records, (r) => r.resourceType === 'Coverage');
  const insuranceProviders = _.get(coverage, 'payor', []);
  return insuranceProviders ? insuranceProviders.map((i) => i.display) : 'No Insurance';
}

const getMRN = (identifiers) => {
  const identifier = _.find(identifiers, (i) => _.get(i, 'type.text') === 'MRN');
  const mrn = _.get(identifier, 'value', "");
  return mrn;
}

const PatientSnapshot = () => {
  const [enroll, setEnroll] = useState(null);
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [buttonLeft, setButtonLeft] = useState("");
  const [buttonRight, setButtonRight] = useState("");
  const [footer, setFooter] = useState("");
  const [dup, setDup] = useState(false);
  const [patients, setPatients] = useState([]);
  const [referSuccess, setReferSuccess] = useState(false);
  const { store, dispatch } = useStore();
  const patient = _.get(store, 'patient', "");
  const id = _.get(store, 'patient.id');
  const nameArray = getNameArray(patient);
  const firstName = nameArray[0];
  const midName = nameArray[1];
  const lastName = nameArray[2];
  const name = useMemo(() => nameArray.join(" "), [store]);
  const address = useMemo(() => getPatientAddress(patient.address), [store]);
  const birthDate = _.get(patient, 'birthDate', "");
  const gender = _.get(patient, 'gender', "");
  const race = _.get(patient, 'extension[0].extension[1].valueString');
  const ethnicity = _.get(patient, 'extension[1].extension[1].valueString');
  const location = _.get(patient, 'address[0].postalCode', "N/A");
  const insuranceProviders = getInsurance(_.get(store, 'records'));
  const mrn = getMRN(patient.identifier);

  useEffect(() => {
    if (firstName || lastName) {
      checkEnrolledStatus()
    }
  }, [firstName, lastName])

  const setPatientEnrollStatus = (patient) => {
    api.get(`Encounter?patient=${patient.id}`).then((res) => {
      // incase of duplicates we will have to address in future, for now we just takae the first encounter in the bundle
      if (res.data.total >= 1) {
        const enrollEncounter = _.get(res, 'data.entry[0]');
        const enrollStatus = _.get(enrollEncounter, 'resource.status', "");
        if (enrollStatus === ENROLLED) {
          dispatch({type: 'updateEnrollStatus', enrollStatus: ENROLLED});
        } 
        else if (enrollStatus === DISCHARGED) {
          dispatch({type: 'updateEnrollStatus', enrollStatus: DISCHARGED});
        }
        else if (enrollStatus === INIT) {
          dispatch({type: 'updateEnrollStatus', enrollStatus: INIT})
        }
        setEnroll(enrollEncounter);
        dispatch({type: 'updateEnrolledPatient', enrolledPatient: patient});
      } else if (res.data.total === 0){
        dispatch({type: 'updateEnrollStatus', enrollStatus: REFER_PATIENT});
      }
    });
  }
  
  const checkEnrolledStatus = () => {
    api.get(`Patient?identifier=${id}`).then((data) => {
      if (data.data.total === 1) {
        const patient = _.get(data, 'data.entry[0].resource');
        setPatientEnrollStatus(patient);
      } 
      else {
        api.get(`Patient?given=${firstName}&family=${lastName}&birthdate=${birthDate}`).then((res) => {
          if (res.data.total === 1) {
            const patient = _.get(res, 'data.entry[0].resource');
            setPatientEnrollStatus(patient)
          } else if (res.data.total > 1) {
            setPatients(res.data.entry);
            setDup(true);
          } else {
            dispatch({type: 'updateEnrollStatus', enrollStatus: REFER_PATIENT})
            setModalContent(<div className={classes['modal-main-message']}>We didn't find the patient's profile in Unified Care. Would you like to refer the patient to the program?</div>);
            setButtonLeft(<Button className={classes['modal-button']} type='primary' onClick={() => handleReferPatient(store.patient)}>Refer</Button>)
            setButtonRight(<Button className={classes['modal-button']} type='secondary' onClick={() => setVisible(false)}>Later</Button>)
            setVisible(true);
          }
        })
      }
    }).catch((e) => console.log(e))
  }
  
  const handleReferPatient = (patient) => {
    const newPatientId = new ObjectId().toString();
    const newPatient = _.omit(patient, ['managingOrganization', 'generalPractitioner']);
    const organizationId = getOrganizationId(_.get(store, 'ihPractitioner'));
    const practitionerId = _.get(store, 'ihPractitioner.data.id');
 
    newPatient.id = newPatientId;
    newPatient.managingOrganization = {
      reference: `Organization/${organizationId}`
    };
    newPatient.generalPractitioner = {
      reference: `Practitioner/${practitionerId}`
    };

    createPatient(newPatient).then((res) => {
      if (res.status === 201) {
        const patientId = _.get(res, 'data.id');
        const coverage = _.find(store.records, (r) => r.resourceType === 'Coverage');
        if (coverage) {
          const newCoverage = makeCoverage(coverage, patientId);
          createCoverage(newCoverage).catch((e) => console.log(e))
        }
        dispatch({type: 'updateEnrolledPatient', enrolledPatient: res.data});
        dispatch({type: 'updateEnrollStatus', enrollStatus: REFER_PATIENT});
        setVisible(false);
        setDup(false);
        setReferSuccess(true);
      }
    })
  }
  
  const renderEnroll = () => {  
    const enrollStatus = _.get(store, 'enrollStatus');
    let tag;

    if (enrollStatus === ENROLLED) {
      const lastUpdated = moment(_.get(enroll, 'resource.meta.lastUpdated')).format('MM/DD/YYYY');
      tag = <div className={classes['enrollment-status-enrolled']}>Enrolled, {lastUpdated}</div>
    }
    else if (enrollStatus === INIT) {
      tag = <div className={classes['enrollment-status-pending']}>waiting for the enrollment confirmation</div>
    }
    else if (enrollStatus === REFER_PATIENT) {
      tag = <Button className={classes['enroll-button']} type='primary' onClick={() => checkEnrolledStatus()}>
        <Tooltip overlayStyle={{backgroundColor: '#22222'}} title="Note: You can click this button to refer a patient in the program.">
          Refer Patient
        </Tooltip>
        </Button>
    }
    else if (!enrollStatus && patients.length) { // case where duplicate patients are found
      tag = <Button className={classes['enroll-button__dup']} type='danger' onClick={() => checkEnrolledStatus()}>
      <Tooltip overlayStyle={{backgroundColor: '#22222'}} title="Note: You need to select a patient to get measurement data.">
        Select Patient
      </Tooltip>
      </Button>
    }
    
    return tag;
  }

  const renderPatientHeader = () => {
    return <div className={classes['patient-snapshot-header']}>
      <div className={classes['patient-name']}>{nameArray.join(" ")}</div>
      {renderEnroll()}
    </div>
  }

  return (
   patient ? <div className={classes['patient-snapshot']}>
      <FontAwesomeIcon icon="user-circle" className={classes['patient-snapshot__photo']} />
      <div className={classes['patient-snapshot__info']}>
        {renderPatientHeader()}
        <div className={classes['patient-snapshot__demographics']}>
          <div>
            <div>DOB: {birthDate}</div>
            <div>Gender: {gender}</div>
            <div>Race: {race}</div>
            <div>Ethnicity: {ethnicity}</div>
          </div>
          <div>
            <div>Address: {address}</div>
            <div>Location: {location}</div>
            <div>Insurance: {insuranceProviders}</div>
          </div>
        </div>
      </div>
      <TwoButtonMessageModal 
        visible={visible}
        modalContent={modalContent}
        buttonLeft={buttonLeft}
        buttonRight={buttonRight}
        footer={footer}
      />
      <DupPatientListModal
        patients={patients}
        dup={dup}
        setDup={setDup}
        referPatient={handleReferPatient}
        setReferSuccess={setReferSuccess}
        setModalContent={setModalContent}
        setButtonLeft={setButtonLeft}
        setButtonRight={setButtonRight}
        setVisible={setVisible}
        setFooter={setFooter}
        />
      <ReferPatientSuccesModal referSuccess={referSuccess} setReferSuccess={setReferSuccess}/>
    </div> 
      : 
    <Spin/>
  );
};

export default PatientSnapshot;
