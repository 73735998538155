import axios from 'axios';
import { GLUCOSE_CODE, FASTING_GLUCOSE_CODE, BLOOD_PRESSURE_CODE } from '../utils/constants';

export const api = axios.create({
    baseURL: process.env.REACT_APP_HAPI_URL,
    // headers: {
        // 'Authorization': 'Basic ' + process.env.REACT_APP_FHIR_CREDENTIALS,
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS,PATCH',
        // 'Access-Control-Allow-Headers': 'Authorization, Content-Type, Accept, Access-Control-Allow-Origin, Access-Control-Allow-Methods, Access-Control-Allow-Headers',
        // 'Accept': 'application/fhir+json',
    //     'Content-Type': 'application/json',
    //     'Cache-Control': 'no-cache'
    // }
})

export const setBearerToken = (token) => {
    api.defaults.headers.common['Authorization'] = 'Bearer '  + token;
}

export const insertMeasurement = payload => api.post(`/Observation`, payload)
export const getObservations = (id, fromDate, toDate) => {
    try {
        if (fromDate && toDate) {
            return api.get(`/Observation?patient=${id}&date=ge${fromDate}&date=le${toDate}&_sort=-date`)
        } else {
            return api.get(`/Observation?patient=${id}&_count=200&_sort=-date`)
        }
    } catch (e) {
        console.log(e)
    }
}
export const getBgObservations = (id, fromDate, toDate) => {
    try {
        if (fromDate && toDate) {
            return api.get(`/Observation?patient=${id}&date=ge${fromDate}&date=le${toDate}&code=${GLUCOSE_CODE},${FASTING_GLUCOSE_CODE}&_sort=-date`)
        } else {
            return api.get(`/Observation?patient=${id}&code=${GLUCOSE_CODE},${FASTING_GLUCOSE_CODE}&_sort=-date`)
        }
    } catch (e) {
        console.log(e)
    }
}
export const getBpObservations = (id, fromDate, toDate) => {
    try{
        if (fromDate && toDate) {
            return api.get(`/Observation?patient=${id}&date=ge${fromDate}&date=le${toDate}&code=${BLOOD_PRESSURE_CODE}&_sort=-date`)
        } else {
            return api.get(`/Observation?patient=${id}&code=${BLOOD_PRESSURE_CODE}&_sort=-date`)
        }
    } catch (e) {
        console.log(e)
    }
}
export const createPatient = payload => api.put(`/Patient/${payload.id}`, payload)
export const getPatient = (ehr_id) => api.get(`/Patient/${ehr_id}`)
export const getPatients = () => api.get(`/Patient`)
export const createCoverage = (payload) => api.put(`/Coverage/${payload.id}`, payload)
export const createPractitioner = practitioner => api.put(`/Practitioner/${practitioner.id}`, practitioner)

const apis = {
    insertMeasurement,
    getObservations,
    getBgObservations,
    getBpObservations,
    createPatient,
    getPatient,
    getPatients,
    createCoverage
}

export default apis
