import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import '../../App.scss'
import moment from 'moment';
import BGTable from './BGTable';
import BGSummary from './BGSummary';
import BPTable from './BPTable';
import BPSummary from './BPSummary'
import { useInterval } from '../../utils/useInterval';
import { getObservations, getBgObservations, getBpObservations } from '../../api';
import { Spin } from 'antd';
import MeasurementModal from './MeasurementModal';
import { ENROLLED } from '../../utils/constants';

const Measurement = ({ store, loading, client, dispatch, encounter }) => {
  const { enrolledPatient } = store;
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [bgDates, setBgDates] = useState([moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
  const [bpDates, setBpDates] = useState([moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
  const [bgDateDiff, setBgDateDiff] = useState([1, 'week']); // [number, 'days' 'weeks' or 'months']
  const [bpDateDiff, setBpDateDiff] = useState([1, 'week']);
  
  useEffect(() => {
    loadMeasurements(enrolledPatient)
  }, [store.enrolledPatient]);

  useInterval(async () => {
    loadMeasurements(enrolledPatient)
  }, 15000);

  const loadMeasurements = async (enrolledPatient) => {
    if (enrolledPatient) {
      console.log('FETCHING MEASUREMENTS');
      let bg = await getBgObservations(enrolledPatient.id, bgDates[0], bgDates[1]);
      let bp = await getBpObservations(enrolledPatient.id, bpDates[0], bpDates[1]);
      dispatch({ type: 'updateBgMeasurements', bgMeasurements: bg.data.entry || [] });
      dispatch({ type: 'updateBpMeasurements', bpMeasurements: bp.data.entry || [] });
    }
  }

  return loading ? <Spin/> : (
    store.enrollStatus ==  ENROLLED ? 
    <div style={{padding: '2rem 2rem 0 2rem'}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <BGTable  store={store} visible={visible} setVisible={setVisible} modalData={modalData} setModalData={setModalData} bgDates={bgDates} setBgDates={setBgDates} bgDateDiff={bgDateDiff} setBgDateDiff={setBgDateDiff}/>
        <BGSummary  store={store}/>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <BPTable store={store} visible={visible} setVisible={setVisible} modalData={modalData} setModalData={setModalData} bpDates={bpDates} setBpDates={setBpDates} bpDateDiff={bpDateDiff} setBpDateDiff={setBpDateDiff}/>
        <BPSummary store={store}/>
      </div>
      <MeasurementModal visible={visible} setVisible={setVisible} modalData={modalData} setModalData={setModalData} />
    </div>
    : ""
  );
};

export default Measurement;
